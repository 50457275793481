<template>
  <div>
    <vs-table :data="proposalData" height="100%">
      <template slot="thead">
        <vs-th> Invoice Info </vs-th>
        <vs-th> Paid </vs-th>
        <vs-th> Paid Now </vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr :key="indextr" v-for="(proposal, indextr) in data">
          <vs-td>
            <p>
              <span class="font-bold">Code</span> :
              {{ proposalData[indextr].Code }}
            </p>
            <p>
              <span class="font-bold">Supplier Code</span> :
              {{ proposalData[indextr].SupplierCode }}
            </p>
            <p>
              <span class="font-bold">Supplier Name</span> :
              {{ proposalData[indextr].SupplierName }}
            </p>
            <p v-if="poType !== 'CN Supplier'">
              <span class="font-bold">Delivery Number</span> :
              {{ proposalData[indextr].DeliveryNumber }}
            </p>
            <p v-if="poType !== 'CN Supplier'">
              <span class="font-bold">Purchase Invoice Code</span>:
              {{ proposalData[indextr].PurchaseInvoiceCode }}
            </p>
            <p v-if="poType !== 'CN Supplier'">
              <span class="font-bold">Purchase Invoice Code Internal</span> :
              {{ proposalData[indextr].PurchaseInvoiceCodeInternal }}
            </p>
          </vs-td>
          <vs-td>
            {{ priceFormat(findRemaingValue(proposalData[indextr].ID)) }} ({{
              currencyCode
            }})
          </vs-td>
          <vs-td>
            <input-paidnow
              :paidnowValue="findUnpaidValue(proposalData[indextr].ID)"
              :proposalId="proposalData[indextr].ID"
            />
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import moment from "moment";
import InputPaidNow from "./input-paid-now.vue";
export default {
  props: {
    proposalData: Array,
    isOpen: Boolean,
    currencyCode: String,
    unpaidValues: {
      type: Array,
      default: () => [],
    },
    poType: {
      type: String,
      default: "",
    },
  },
  components: {
    "input-paidnow": InputPaidNow,
  },
  methods: {
    dateFormat(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    findUnpaidValue(id) {
      const unpaidValue = this.unpaidValues.find((item) => item.id === id);
      return unpaidValue ? unpaidValue.value : 0;
    },
    findRemaingValue(id) {
      const remainingValue = this.unpaidValues.find((item) => item.id === id);
      return remainingValue ? remainingValue.remaining_value : 0;
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        const temp = [...this.unpaidValues];
        this.$store.commit("clearingDN/setProposalPaidNows", temp);
      } else {
        this.$store.commit("clearingDN/setProposalPaidNows", []);
      }
    },
    unpaidValues() {
      const temp = [...this.unpaidValues];
      this.$store.commit("clearingDN/setProposalPaidNows", temp);
    },
  },
};
</script>
