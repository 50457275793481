var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vx-row mb-12 mt-12"},[_c('div',{class:[
      _vm.detail ? _vm.detailShow + ' vertical-divider' : '',
      _vm.detailHide,
    ]},[_c('data-table',{attrs:{"responseData":_vm.responseData,"propsParams":_vm.params,"header":_vm.header},on:{"reloadDataFromChild":_vm.reloadData}},[_c('template',{slot:"thead"}),_c('template',{slot:"tbody"},_vm._l((_vm.responseData.records),function(tr,indextr){return _c('vs-tr',{key:indextr,class:tr.class},[_c('vs-td',{staticClass:"whitespace-no-wrap"},[_c('div',{staticClass:"mt-4 flex justify-between"},[_c('vx-tooltip',{staticClass:"mr-4",attrs:{"text":"Show Invoice"}},[_c('vs-button',{attrs:{"type":"line","icon-pack":"feather","icon":"icon-eye"},on:{"click":function($event){$event.stopPropagation();return _vm.handleShow(tr)}}})],1)],1)]),_c('vs-td',[_vm._v(" "+_vm._s(tr.code)+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(_vm.formatDate(tr.clearing_date))+" ")]),_c('vs-td',[_vm._v(" ("+_vm._s(tr.supplier_code)+") "+_vm._s(tr.supplier_name)+" ")]),_c('vs-td',_vm._l((_vm.getArrayCodes(tr.related_codes)),function(code,index){return _c('p',{key:`related-${index}`},[_vm._v(" "+_vm._s(code)+" ")])}),0),_c('vs-td',_vm._l((_vm.getArrayCodes(tr.debit_note_codes)),function(code,index){return _c('p',{key:`dn-${index}`},[_vm._v(" "+_vm._s(code)+" ")])}),0),_c('vs-td',[_vm._v(" "+_vm._s(_vm.priceFormat(tr.clearing_total))),_c('br')])],1)}),1)],2)],1),_c('transition',{attrs:{"name":"detail-fade"}},[(_vm.detail)?_c('div',{class:[
        _vm.detail ? _vm.detailShow + 'md:w-2/3 w-full mb-base' : '',
        _vm.detailHide,
      ]},[_c('vs-row',[_c('vs-col',{staticStyle:{"padding-bottom":"8px"},attrs:{"vs-offset":"8","vs-type":"flex","vs-justify":"rigth","vs-align":"rigth","vs-w":"4"}},[_c('vs-button',{staticClass:"ml-auto",attrs:{"size":"small","color":"grey","icon-pack":"feather","icon":"icon-x-square"},on:{"click":_vm.handleClose}},[_vm._v("Close")])],1),_c('vs-col',{attrs:{"vs-offset":"0","vs-type":"flex","vs-justify":"center","vs-align":"center","vs-w":"1"}},[_c('vs-button',{attrs:{"size":"small","color":"grey","icon-pack":"feather","icon":"icon-x-square"},on:{"click":_vm.handleClose}})],1),_c('detail-div',{attrs:{"selected":_vm.selectedData},on:{"close":_vm.handleClose}})],1)],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }