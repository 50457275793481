var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vs-popup',{ref:"dialog",attrs:{"active":_vm.isOpen,"button-close-hidden":"","fullscreen":"","title":"DN"},on:{"update:active":function($event){_vm.isOpen=$event}}},[_c('div',{staticClass:"flex flex-col",style:({ minHeight: _vm.contentHeight })},[_c('vs-tabs',{attrs:{"value":_vm.currentTab}},[_c('vs-tab',{attrs:{"label":"Clearing"}},[_c('div',{staticClass:"flex flex-row"},[_c('div',{staticClass:"p-4 w-1/2 overflow-y-auto border-r border-l-0 border-t-0 border-b-0 border-solid border-grey-light",style:({ minHeight: _vm.rowHeight, maxHeight: _vm.rowHeight })},[(!_vm.isLoading)?_c('debit-note',{attrs:{"supplierCode":_vm.SupplierCode,"supplierID":_vm.SupplierID,"isOpen":_vm.isOpen,"poType":_vm.poType},on:{"resetIsLoading":_vm.resetIsLoading}}):_vm._e()],1),_c('div',{staticClass:"p-4 w-1/2 overflow-y-auto",style:({ minHeight: _vm.rowHeight, maxHeight: _vm.rowHeight })},[_c('selected-proposals',{attrs:{"proposal-data":_vm.selectedProposal,"currencyCode":_vm.currencyCode,"isOpen":_vm.isOpen,"unpaidValues":_vm.unpaidValues,"poType":_vm.poType}})],1)]),_c('vs-divider'),_c('div',{staticClass:"flex flex-row justify-between w-full gap-4"},[_c('total-info',{attrs:{"title":'Total Paid Now Selected Proposal',"value":_vm.priceFormatWithMinus(
                this.$store.state.clearingDN.totalSelectedPaidNows,
                !_vm.currencyCode ? '' : _vm.currencyCode
              )}}),_c('total-info',{attrs:{"title":'Total Selected DN',"value":_vm.priceFormatWithMinus(
                this.$store.state.clearingDN.totalSelectedDebitNotesValue,
                !_vm.currencyCode ? '' : _vm.currencyCode
              )}}),_c('total-info',{attrs:{"title":'Total Proposal - Total DN',"value":_vm.calculateProposalMinusDN(!_vm.currencyCode ? '' : _vm.currencyCode)}})],1),_c('div',{staticClass:"flex flex-row justify-end gap-4"},[_c('vs-button',{staticClass:"sticky",attrs:{"color":"danger"},on:{"click":function($event){return _vm.closeModal(false)}}},[_vm._v("Cancel")]),_c('vs-button',{staticClass:"sticky",on:{"click":function($event){return _vm.nextTab()}}},[_vm._v("Clearing")])],1)],1),_c('vs-tab',{attrs:{"label":"Schema"}},[_c('clearing-schema',{attrs:{"currentTab":_vm.currentTab,"selectedProposal":_vm.selectedProposal,"poType":_vm.poType},on:{"backTab":_vm.backTab,"clearing":_vm.handleClearing}})],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }